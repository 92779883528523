body {
  position: absolute;
  top: 0;
  font-family: Nunito;
  left: 0;
  margin: 0;
  min-height: 100vh;
  min-width: 100vw;
  overflow: auto;
  overflow-x: hidden;
}

.footer {
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;
  flex-flow: column;
  background-color: #0872c8;
  padding-top: 20px;
  padding-bottom: 20px;
}

.phoneButton {
  background: #FF6F6F;
  position: fixed;
  z-index: 10;
  bottom: 24px;
  right: 24px;
  width: 76px;
  height: 76px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.24);
  cursor: pointer;
  border-radius: 100%;
  display: flex;
  align-items: center;
  transition: all .2s;
  justify-content: center;
}
.phoneButton:active {
  transform: translateY(1px);
}

* {
  -webkit-tap-highlight-color: transparent;
}

table {
  border-top: 3px #008EFF solid;
}

th {
  border-bottom: 1px rgb(240, 240, 240) solid;
  padding-top: 10px;
  padding-bottom: 10px;
}

table {
  border-spacing: 0px;
  border-collapse: collapse;
}

input {
  appearance: none;
  outline: none;
  border: none;
  font-size: 21px;
  background: rgb(245, 245, 245);
  border-radius: 8px;
  padding-right: 8px;
  padding-left: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media screen and (max-device-width: 900px){
  body{
    -webkit-text-size-adjust: none;
  }
}

.modalClose {
  cursor: pointer;
  user-select: none;
  display: flex;
  justify-content: flex-end;
  font-size: 24px;
}
.modalClose:hover {
  opacity: 0.8;
}
.modalClose:active {
  opacity: 0.6;
}
.modal {
  z-index: 101;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  background: rgba(24, 24, 24, 0.541);
}
.modalCard {
  background: white;
  border-radius: 4px;
  width: 100%;
  max-width: 800px;
  padding: 20px;
}
.Header {
  position: fixed;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  background: #C8E6FF;
  transition: all .1s;
  border-bottom: 1px solid rgba(79, 72, 163, 0.16);;
}
.HeaderMobile {
  display: none;
}
.mobile .Header {
  display: none !important;
}
.mobile .HeaderMobile {
  display: flex !important;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  border-bottom: 1px solid rgba(161, 161, 161, 0.16);;
  height: 75px;
  position: fixed;
  top: 0;
  cursor: pointer;
  left: 0;
  padding-right: 15px;
  padding-left: 15px;
  width: calc(100% - 30px);
  z-index: 100;
}
.HeaderMobileItem {
  height: 100%;
  width: calc(100% - 10px);
  padding-right: 5px;
  padding-left: 5px;
  cursor: poiner;
  user-select: none;
  -webkit-user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s;
  border-radius: 100%;
  flex-flow: column;
}
.HeaderMobileItem.true > * {
  color: #005BA5 !important;
}
.HeaderMobileItem * {
  pointer-events: none;
  font-size: 14px;
}
.HeaderMobileItem:active {
  background: rgb(226, 226, 226);
}

.mobile .inmobilehide {
  display: none !important;
}
.onlymobile {
  display: none;
}
.mobile .onlymobile {
  display: block !important;
}
.mobile .PromoFirst {
  height: auto !important;
}

.Header > a {
  margin-right: 30px;
  margin-left: 30px;
  text-decoration: none !important;
  color: #005BA5 !important;
  appearance: none !important;
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 33px;
}

.Header > .Button {
  margin-left: 30px;
}

.Header.inscroll {
  border-bottom: none !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.13);
  background: white !important;
}

.Button {
  padding: 24px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: #005BA5;
  user-select: none;
  cursor: pointer;
  font-family: Nunito;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.13);
  border-radius: 6px;
  color: white;
  transition: all .1s;
}

.OurItem {
  padding: 22px;
  border-radius: 26px;
  user-select: none;
  cursor: pointer;
  margin-top: 30px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.082);
  width: calc(50% - 70px);
  margin-right: 20px;
  transition: all .1s;
  position: relative;
}
.mobile .OurItem {
  width: calc(100%);
  margin-right: 0px;
}
.OurItem.tappable::after {
  content: '';
  height: 18px;
  width: 18px;
  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" style="fill: rgb(202, 202, 202)" class="bi bi-chevron-left" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/></svg>');
  position: absolute;
  bottom: 12px;
  right: 12px;
  transform: rotate(180deg);
}

.OutItem > * {
  pointer-events: none;
  cursor: pointer;
}

.OurItem.tappable:active {
  transform: scale(0.98);
}

.Button:hover {
  background: #0063b4;
}

.Button:active {
  transform: translateY(1px);
}

.Button.Twice {
  background: #0074D0 !important;
}
.Button.Twice:hover {
  background: #0078da !important;
}

.contactCard {
  border: 1px solid #DBDBDB;
  padding: 20px;
  border-radius: 12px;
}

.homeContent {
  width: 720px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
}

.mobile .homeContent {
  width: calc(100vw - 40px);
}
.mobile .inmobilehomecontentbanner {
  width: calc(100% - 0px) !important;
}

.PromoFirst {
  height: 66vh;
  width: 100%;
  background: #C8E6FF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rightLine {
  font-size: 18px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-left: 4px #008EFF solid;
  padding-left: 14px;
  margin-top: 12px;
}